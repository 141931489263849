.header-container {
    background-color: #eae7dc;
    /* z-index: 99999999; */
    /* border: 2px solid red; */
    max-width: 1750px;
    margin: 0 auto;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

.nav-bar div {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.nav-bar > div:nth-child(1){
    width: 375px;

}

.nav-bar > div:nth-child(4) {
    display: none;
}

.nav-bar div a, .nav-bar div p {
    color: #8e8d8a;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
}

.nav-bar div a:hover {
    color: #e85a4f;
}

.nav-bar .contact a {
    border: 1.5px solid #e98074;
    border-radius: 2px;
    padding: 15px 35px;
    font-weight: 900;
    box-shadow: inset 0 0 0 0 #e85a4f;
    transition: ease-out 0.4s;
}

.nav-bar .contact a:hover {
    box-shadow: inset 400px 0 0 0 #e85a4f;
    color: white;
    border: 1px solid white;
    font-style: normal;
}

.nav-bar div p {
    cursor: pointer;
}

@media(max-width: 768px){

    [popover] {
        inset: unset;
        
    }
    .nav-bar {
        justify-content: space-between;
    }

    .nav-bar > div:nth-child(1), .nav-bar > div:nth-child(3) {
        display: none;
    }

    .nav-bar > div:nth-child(2) {
        width: max-content;
    }

    .nav-bar > div:nth-child(2) > p {
        font-size: 28px;
        font-weight: 900;
    }
    
    .nav-bar > div:nth-child(4) {
        display: inline-block;
        width: max-content;
    }

    .cancel-burger-menu {
        width: 20px;
        height: 20px;
        margin-top: 10px;
    }
    
    .header-container > .burger-menu {
        height: 125px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-bottom: 5px;
    }

    .header-container > .burger-menu > div > img {
        width: 30px;
        height: 30px;
    }

    .header-container > .burger-menu > a {
        color: #8e8d8a;
        text-decoration: none;
        font-size: 26px;
        font-weight: 400;
        transition: ease-out 0.06s;
    }
}