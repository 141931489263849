@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');


* {
  margin: 0;
  font-family: 'Playfair Display', serif;
  scroll-behavior: smooth;
}

body {
  /* background-color: #272727; */
  background-color: #eae7dc;
}