.skills-section {
    /* border: 3px solid red; */
    width: 90%;
    max-width: 1400px;
    /* margin: 0 auto 40px; */
    margin: 0 auto;
    height: 550px;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    overflow: hidden;
}

.skills-section > div {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eae7dc;
    padding-top: 60px;
}


.skills-section > div:nth-child(2) {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.skills-section > div > h2 {
    color: #767572;
    margin: 20px 15px 25px;
    text-align: center;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
    letter-spacing: -.5px;
    border-bottom: 2px solid #6f01fe;
    padding: 8px 0;
}

.skills-section > div > div > p {
    color: rgb(84, 80, 80);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -.5px;
    margin: 6px 0;
}

.icon {
    color: #dedcd7;
    background-color: #e98074;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 15px;
    overflow: visible;
}

@media(max-width: 768px){
    .skills-section {
        margin: -65px auto;
        width: 90%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .skills-section > div {
        width: 100%;
        padding: 30px 0;
    }

    .skills-section > div:nth-child(2) {
    border-top: 2px solid rgb(18, 18, 18);
    border-bottom: 2px solid black;
    }

    .skills-section > div > h2 {
        /* border: none; */
        /* border: 2px solid royalblue; */
        width: 250px;
    }
}