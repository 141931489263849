.contact-container {
    background-color: #eae7dc;
    padding: 50px 0;
    margin-bottom: 10px;
}

.contact-container > h2 {
    margin: 0 auto 30px;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -.5px;
}

.form-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
}

.form-container > section {
    display: flex;
    align-items: center;
}

.form-container > section > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    box-shadow: 0 4px 8px black;
}

.email-icon {
    margin-bottom: 110px;
}

.sent-email-icon {
    margin-top: 110px;
    margin-left: -80px;
}

.form-container > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: -50px;
}

.form-container > div > div  > label {
    display: inline-block;
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 2px;
}

.form-container > div > div > input {
    border: 1px solid black;
    border-radius: 5px;
    width: 325px;
    height: 30px;
    margin-bottom: 10px;
    padding: 8px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.3px;
}

.form-container > div > div > label {
    display: inline-block;
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 2px;
}

.form-container > div > div > textarea {
    resize: none;
    border: 1px solid black;
    border-radius: 5px;
    width: 325px;
    height: 110px;
    margin-bottom: 10px;
    padding: 8px;
    outline: none;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.3px;

}

.form-container > div > div > button {
    display: block;
    width: 200px;
    padding: 10px 0;
    margin: 0 auto;

    font-size: 18px;
    letter-spacing: -.3px;
    color: #8e8d8a;
    background-color: #eae7dc;

    border: 1.5px solid #e98074;
    border-radius: 2px;
    font-weight: 900;
    box-shadow: inset 0 0 0 0 #ff4e42;
    transition: ease-out 0.4s;
}

.form-container > div > div > button:hover {
    box-shadow: inset 400px 0 0 0 #ff4e42;
    color: white;
    cursor: pointer;
}

@media(max-width: 768px){
    .contact-container {
        padding: 50px 15px;
        margin-bottom: 0;
    }

    .contact-container > h2 {
        text-wrap: balance;
    }

    .form-container {
        flex-direction: column;
    }

    .form-container > section {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
    }

    .form-container > section > img {
        width: 215px;
        height: 215px;
        border-radius: 50%;
        box-shadow: 0 4px 8px black;
        margin: 0;
    }

    .email-icon {
        position: relative;
        left: -3rem;
        top: 1.5rem;
    }
    
    .sent-email-icon {
        position: relative;
        left: 3rem;
        bottom: 1.5rem;
    }

    .form-container > div {
        margin: 0;
    }

    .form-container > div > div > button {
        box-shadow: inset 400px 0 0 0 #ff4e42;
        color: white;
    }
}