.experience-area {
    /* background-color: #e98074; */
    background-color: #272727;
    /* margin-top: -90px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.experience-intro {
     width: max-content;
     margin-top: 60px;
}
    
.experience-intro > h2 {
    color: whitesmoke;
    /* color: #e85a4f; */
    /* color: rgb(110, 7, 243); */
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -.4px;
    margin-bottom: 15px;
}


.experience-container {
    /* border: 2px solid black; */
    width: 85%;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 40px 0;
    max-width: 1300px;
    padding: 40px 0;
}

.one-experience {
    border: 4px solid rgb(110, 7, 243);
    border-radius: 25px;
    background-color: #dcdad3;
    width: 600px;
    height: 250px;
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    box-shadow: 0 4px 10px rgb(110, 7, 243);
    transition: height .5s ease-in-out;
    overflow: hidden;
}

.load-more-ul {
    height: 215px;
}

.load-more {
    height: 400px;
    padding-bottom: 45px;
}

.hide-ul {
    opacity: 0;
    overflow: none;
}
.show-ul {
    opacity: 1;
    transition: opacity .8s ease-in-out;
}

.load-more-btn {
    width: 110px;
    height: 40px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    color: #6E07F3;
    background-color: rgba(110, 7, 243, .2);
    font-size: 15px;
    letter-spacing: -.3px;
    border: none;
}

.load-more-btn:hover {
    box-shadow: 0 1px 5px #6E07F3;
}

.move-right {
    margin-left: auto;
}

.one-experience > h2 {
    color: #6E07F3;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -.5px;
    font-weight: 500;
    margin: 15px 0 5px 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.one-experience > h2 > .experience-icon {
    width: 45px;
    height: 45px;
}

.one-experience > p {
    margin-top: 0;
    line-height: 34px;
    letter-spacing: -.15px;
    font-weight: 900;
}

.one-experience > p:nth-child(2) {
    margin-bottom: -10px;
}

.one-experience > ul {
    padding: 4px;
}

.one-experience > ul > li {
    margin-bottom: 2px;
}

@media(max-width: 768px){
    .load-more-button {
        display: block;
    }

    .experience-intro > h2 {
        margin-bottom: 5px;
    }

    .one-experience {
        margin: 0 auto;
        width: 50%;
        height: 220px;
    }

    .move-right {
        margin-left: none;
    }

    .load-more-ul > li {
        display: none
    }

    .load-more {
        border: 2px solid royalblue;
        height: 625px;
    }

    .show-ul > li {
        list-style-type: disc;
    }
}