.popover-container {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    max-width: 250px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateY(10px);
    z-index: 1;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.popover-container.show {
    opacity: 1;
    transform: translateY(0px);
}

.popover-container > p {
    text-align: center;
}

.popover-container > button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
}

@media(max-width: 768px){  
    .popover-container.show {
        transform: translateX(-90px);
        width: 110px;
    }

    .popover-container > p {
        text-align: left;
    }
}
