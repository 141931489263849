.hero-section {
    background-color: #eae7dc;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    line-height: 40px;
    letter-spacing: -.5px;
    padding-bottom: 30px;
}

.hero-container {
    display: flex;
    justify-content: center;
}

.hero-container > div {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 999;
}

.hero-container > div > div {
    margin: 10px 0;
}

.hero-container > div > div > p {
    color: #e98074;
}

.hero-container > div > div > p > span {
    display: inline-block;
    color: #e85a4f;
    text-shadow: #e85a4f 1px 0 30px;
    margin-top: 10px;
    font-weight: 900;
}

.hero-container > div > div > p > span > p {
    display: inline;
    text-decoration: underline;
    text-decoration-color: #e85a4f;
}

.hero-container > img {
    border: 2px solid #8e8d8a;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    box-shadow: 0 4px 8px black;
}

@media(max-width: 768px){
    .hero-section {
        height: max-content;
        line-height: 45px;
    }
    .hero-container {
        padding: 20px 0;
        flex-direction: column;
        align-items: center;
    }

    .hero-container > div {
        margin-bottom: 40px;
    }

    .hero-container > div > div > p > span {
        font-size: 40px;
        margin-top: 0;
    }

    .hero-container > img {
        width: 300px;
        height: 300px;
    }
}