.about-section {
    background-color: #6f01fe;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;
}

.about-section > div {
    /* border: 3px solid red; */
    color: white;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-section > div > h1 {
    padding: 60px 0 20px;
    font-size: 28px;
    letter-spacing: -.5px;
}

.about-section > div > h2 {
    /* border: 1px solid red; */
    width: 765px;
    /* width: 100%; */
    text-wrap: balance;
    line-height: 1.5;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 25px;
}

.about-section > div > div {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}

.about-section > div > div > a > img {
    width: 40px;
}

.about-section > div > div > a > img:hover {
    cursor: pointer;
    box-shadow: 0 5px 15px white;
}

@media(max-width: 768px){
    .about-section {
        padding-top: 10px;
        padding-bottom: 115px;
    }

    .about-section > div {
        padding-left: 0;
        align-items: flex-start;
        text-align: left;
    }

    .about-section > div > h1 {
        width: max-content;
        margin-left: 10px;
    }
    
    .about-section > div > h2 {
        width: 350px;
        font-weight: 900;
        margin-left: 10px;
    }

    .about-section > div > div {
        margin: 10px auto 50px;
    }

    .about-section > div > div > a > img {
        width: 50px;
    }
}